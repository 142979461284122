import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Player from './pages/Player';
import Punishments from './pages/Punishments';
import Navbar from './components/Navbar';
import RedirectComponent from './components/RedirectComponent';
import NotFound from './pages/404';

function App() {
  return (
    <Router>
      <div className="App drawer drawer-mobile">
        <input id="my-drawer" type="checkbox" className="drawer-toggle" />
        
        <div className="drawer-content">
          <Navbar />
          <main>
            <Routes>
              <Route path="/" element={<Home />} />

              {}
              <Route path="/support" element={<RedirectComponent to="https://support.notreal003.xyz" />} />
              <Route path="/report" element={<RedirectComponent to="https://request.notreal003.xyz/report" />} />
              <Route path="/apply" element={<RedirectComponent to="https://request.notreal003.xyz/support" />} />
              <Route path="/guild" element={<RedirectComponent to="https://request.notreal003.xyz/apply" />} />
              <Route path="/discord" element={<RedirectComponent to="https://discord.gg/sqVBrMVQmp" />} />
              <Route path="/d" element={<RedirectComponent to="https://discord.gg/sqVBrMVQmp" />} />
              <Route path="/ng-status" element={<RedirectComponent to="https://status.notreal003.xyz" />} />
              <Route path="/status" element={<RedirectComponent to="https://status.notreal003.xyz" />} />
              <Route path="/terms" element={<RedirectComponent to="https://support.notreal003.xyz/terms" />} />
              <Route path="/privacy" element={<RedirectComponent to="https://support.notreal003.xyz/privacy" />} />
              <Route path="/request" element={<RedirectComponent to="https://request.notreal003.xyz" />} />
              <Route path="/requests" element={<RedirectComponent to="https://request.notreal003.xyz" />} />
              <Route path="/bot" element={<RedirectComponent to="https://discord.com/oauth2/authorize?client_id=1154035556993470565&permissions=1135782425656385&integration_type=0&scope=applications.commands+bot" />} />
              <Route path="/yt" element={<RedirectComponent to="https://youtube.com/@notnt77?si=VYMvcWcwVCnpeZGL" />} />
              <Route path="/youtube" element={<RedirectComponent to="https://youtube.com/@notnt77?si=VYMvcWcwVCnpeZGL" />} />
              <Route path="/x" element={<RedirectComponent to="https://x.com/NotNT77" />} />
              <Route path="/twitter" element={<RedirectComponent to="https://x.com/NotNT77" />} />
              <Route path="/github" element={<RedirectComponent to="https://github.com/NotReal003" />} />

              {}
              <Route path="/player" element={<Player />} />
              <Route path="/Punishments" element={<Punishments />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </main>
        </div>
      </div>
    </Router>
  );
}

export default App;
