import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <header className="navbar bg-base-100">
      <div className="flex-1">
        <Link to="/" className="btn no-animation btn-ghost text-sm">NotNT | NotReal</Link>
      </div>
      <div className="flex-none">
        <ul className="dropdown dropdown-end menu menu-horizontal px-1">
          <li>
            <details>
              <summary>Links</summary>
              <ul className="bg-base-100 rounded-t-none shadow-lg p-2">
                <li><Link to="https://request.notreal003.xyz">Requests</Link></li>
                <li><Link to="/player">Players</Link></li>
                <li><Link to="/support">Support</Link></li>
                <li><Link to="https://request.notreal003.xyz/apply">Guild Application</Link></li>
              </ul>
            </details>
          </li>
        </ul>
        <div className="dropdown dropdown-bottom dropdown-end">
          <div tabIndex={0} role="button" className="btn m-1 btn-sm">
            Display
            <svg
              width="12px"
              height="12px"
              className="inline-block h-2 w-2 fill-current opacity-60"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 2048 2048">
              <path d="M1799 349l242 241-1017 1017L7 590l242-241 775 775 775-775z"></path>
            </svg>
          </div>
          <ul tabIndex={0} className="dropdown-content bg-base-300 rounded-box z-[1] w-52 p-2 shadow-2xl">
            <li>
              <input
                type="radio"
                name="theme-dropdown"
                className="theme-controller btn btn-sm btn-block btn-ghost justify-start"
                aria-label="Default"
                value="default" />
            </li>
            <li>
              <input
                type="radio"
                name="theme-dropdown"
                className="theme-controller btn btn-sm btn-block btn-ghost justify-start"
                aria-label="Dracula"
                value="dracula" />
            </li>
            <li>
              <input
                type="radio"
                name="theme-dropdown"
                className="theme-controller btn btn-sm btn-block btn-ghost justify-start"
                aria-label="Aqua"
                value="aqua" />
            </li>
            <li>
              <input
                type="radio"
                name="theme-dropdown"
                className="theme-controller btn btn-sm btn-block btn-ghost justify-start"
                aria-label="Night"
                value="night" />
            </li>
            <li>
              <input
                type="radio"
                name="theme-dropdown"
                className="theme-controller btn btn-sm btn-block btn-ghost justify-start"
                aria-label="Winter"
                value="winter" />
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Navbar;