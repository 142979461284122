import React from 'react';

const BioSection = () => {
  return (
    <section className="py-10 bg-base-100">
      <div className="container mx-auto text-center rounded-lg">
        <h2 className="text-xl font-bold">About me</h2>
        <p className="ml-3 mr-3 text-inline">I'm NotNT, now known as NotReal003, a gamer, content creator, and beginner developer. Mostly, I use HTML, CSS, and JavaScript for webs. <a href="discord:/users/1131271104590270606" target="_blank" rel="noopener noreferrer">Contact Me</a> for any inquiries.</p>
      </div>
    </section>
  );
};

export default BioSection;
