import React from 'react';
import { ImExit } from "react-icons/im";

const DataSection = () => {
  return (
    <section className="py-10 bg-base-200">
      <div className="container mx-auto text-center rounded-lg shadow-md">
        <div className="icon mb-2">
         <ImExit className="mx-auto size-4"/>
        </div>
        <h3 className="text-2xl font-bold">Cya</h3>
        <p className="mt-2 text-sm text-inline ml-3 mr-3">Again, thanks for visiting this page, if you need anything, feel free to contact me :)</p>
      </div>
    </section>
  );
};

export default DataSection;
