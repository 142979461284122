// src/pages/punishments.jsx

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const Punishments = () => {
  const { playerName } = useParams();
  const [punishments, setPunishments] = useState([]);
  const [selectedPunishment, setSelectedPunishment] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.ngmc.co/v1/players/skggamerzs90/punishments`);
        setPunishments(response.data.punishments);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [playerName]);

  const handlePunishmentClick = (punishment) => {
    setSelectedPunishment(punishment);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Punishments for '{playerName}'</h1>
      <div className="form-control mb-4">
        <label className="cursor-pointer label">
          <span className="label-text">Show alt punishments</span>
          <input type="checkbox" className="toggle" />
        </label>
      </div>
      <div className="space-y-4">
        {punishments.map((punishment) => (
          <div
            key={punishment.id}
            className={`card ${punishment.active ? 'bg-red-300' : 'bg-green-300'} shadow-xl`}
            onClick={() => handlePunishmentClick(punishment)}
          >
            <div className="card-body">
              <h2 className="card-title">
                {punishment.type === 'BAN' ? 'Ban' : 'Mute'} {punishment.active ? 'ACTIVE' : 'EXPIRED'}
              </h2>
              <p>
                {punishment.permanent
                  ? 'Permanent'
                  : new Date(punishment.validUntil * 1000).toLocaleString('en-US', {
                      timeZone: 'Asia/Kolkata',
                      timeZoneName: 'short',
                    })}
              </p>
            </div>
          </div>
        ))}
      </div>

      {selectedPunishment && (
        <div className="modal modal-open">
          <div className="modal-box">
            <h3 className="font-bold text-lg">Ban Details</h3>
            <p>ID: {selectedPunishment.id}</p>
            <p>Reason: {selectedPunishment.reason}</p>
            <p>Valid Until: {new Date(selectedPunishment.validUntil * 1000).toLocaleString('en-US', {
                timeZone: 'Asia/Kolkata',
                timeZoneName: 'short',
              })}</p>
            <p>Issued At: {new Date(selectedPunishment.issuedAt * 1000).toLocaleString('en-US', {
                timeZone: 'Asia/Kolkata',
                timeZoneName: 'short',
              })}</p>
            <p>Active: {selectedPunishment.active ? 'Yes' : 'No'}</p>
            <p>Alt Punishment: {selectedPunishment.alt ? 'Yes' : 'No'}</p>
            <p>Affected Players: {selectedPunishment.affectedPlayers.join(', ')}</p>
            <div className="modal-action">
              <button className="btn" onClick={() => setSelectedPunishment(null)}>Close</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Punishments;
