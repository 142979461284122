import React from 'react';
import { Link } from "react-router-dom"
import { IoGitPullRequest } from "react-icons/io5";

const Hero = () => {
  return (
    <section className="hero min-screen bg-base-200 flex items-center">
      <div className="container mx-auto text-center mt-10 mb-10">
        <h2 className="text-5xl font-bold">Welcome!</h2>
        <p className="py-6">Heya! welcome!</p>
      <Link to="https://request.notreal003.xyz" className="btn btn-outline btn-info"><IoGitPullRequest />Requests</Link>
      </div>
    </section>
  );
};

export default Hero;
