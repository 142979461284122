import React from 'react';
import BioSection from '../components/BioSection';
import CTASection from '../components/CTASection';
import Features from '../components/Features';
import DataSection from '../components/DataSection';
import Hero from '../components/Hero';
import Footer from '../components/Footer';
const Home = () => {
  return (
    <>
      <Hero />
      <BioSection />
      <CTASection />
      <Features />
      <DataSection />
      <Footer />
    </>
  );
};

export default Home;
