import React from 'react';
import { Link } from 'react-router-dom';
import { FaGithub, FaDiscord, FaWpforms, FaYoutube } from 'react-icons/fa';
import { IoStatsChart } from "react-icons/io5";

const CTASection = () => {
  return (
    <section className="py-10 bg-base-200">
      <div className="container mx-auto text-center flex flex-wrap justify-center gap-4">
        <a href="submissions/guild-application" className="btn btn-outline btn-info"><FaWpforms /> Guild Application</a>
        <Link to="/player" className="btn btn-outline btn-info"><IoStatsChart />Player Profile</Link>
        <a href="https://github.com/NotReal003" className="btn btn-outline btn-info"><FaGithub />GitHub</a>
        <a href="https://youtube.com/@NotNT77?si=j7kgkkCkrha0aNNc" className="btn btn-info btn-outline"><FaYoutube />YouTube</a>
        <a href="https://discord.gg/sqVBrMVQmp" className="btn btn-outline btn-info"><FaDiscord /> Join Discord Server</a>
      </div>
    </section>
  );
};

export default CTASection;
