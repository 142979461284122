import React from 'react';
import { MdOutlineSupportAgent } from "react-icons/md";
import { FaWpforms } from "react-icons/fa6";
import { AiFillDatabase } from "react-icons/ai";

const Features = () => {
  return (
    <section className="py-10 bg-base-100">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl mb-2 font-bold">Something else</h2>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-8 mt-4">
          <div className="feature p-4 bg-base-200 rounded-lg shadow-md">
            <div className="icon mb-2">
              <FaWpforms className="mx-auto size-6"/>
            </div>
            <h3 className="text-xl font-semibold mb-2">Requests</h3>
            <p>You may always submit a request at our <a href="https://request.notreal003.xyz"> Request Page</a> :)</p>
          </div>
          <div className="feature p-4 bg-base-200 rounded-lg shadow-md">
            <div className="icon mb-2">
             <MdOutlineSupportAgent className="mx-auto size-6"/>
            </div>
            <h3 className="text-xl font-semibold mb-2">Support</h3>
            <p>We have our helpdesk, and there are many helpful articles that may help you :). Visit our<a href="https://support.notreal003.xyz"> HelpDesk by clicking here!</a></p>
          </div>
          <div className="feature p-4 bg-base-200 rounded-lg shadow-md">
            <div className="icon mb-2">
              <AiFillDatabase className="mx-auto size-6"/>
            </div>
            <h3 className="text-xl font-semibold mb-2">Data Collection</h3>
            <p>No data is collected from users on this website. Your privacy is important to us, and we do not store or process any personal information. Feel free to explore our content without concerns about your data being collected. Read more about GitHub privacy policy by <a href="https://notreal003.github.io/support/privacy">clicking here!</a> and Read about our Privacy policy by <a href="https://support.notreal003.xyz/privacy">Clicking Here!</a></p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
